import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router' // included with gatsby
// Components
import { Loading, Seo, ProductCard, ShoppingWithBanner } from '../../components'
import RouterComponent from '../../components/RouterWrapper'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useStoreContext } from '../../context/StoreContext'
// Images
import categoryImg from '../../images/category/category_test.jpeg'
// Services & Utils
import { Magento } from '../../services/magento'
import { filterProducts } from '../../utils/productHelpers'
// Styles
import {
  ImageOverlay,
  ProductCategoryHeader,
  ProductCategoryTitle,
  ProductsGrid,
  Wrapper,
} from '../../styles/LandingPage.styles'

const Benefit = ({ url_key, categoryProducts }) => {
  const [benefit, setBenefit] = useState(categoryProducts)

  const { storeData, isStoreLoading } = useStoreContext()

  useEffect(() => {
    if (!isStoreLoading) {
      const subCategories = storeData?.benefit?.subCategories
      if (subCategories) {
        setBenefit(subCategories[`${url_key}`])
      }
    }
  }, [isStoreLoading, url_key])

  return (
    <>
      <Seo title={benefit?.name ?? ''} />
      <ShoppingWithBanner />
      <Wrapper>
        <ProductCategoryHeader backgroundImg={benefit?.image ?? categoryImg}>
          <ImageOverlay>
            <ProductCategoryTitle>{benefit?.name ?? ''}</ProductCategoryTitle>
          </ImageOverlay>
        </ProductCategoryHeader>
        <ProductsGrid>
          {benefit?.products?.map(product => (
            <ProductCard key={product.uid} {...product} />
          ))}
        </ProductsGrid>
      </Wrapper>
    </>
  )
}

const BenefitPage = props => {
  const [categoryProducts, setCategoryProducts] = useState(null)

  const {
    prismicData: {
      prismicProductsPage: { loading_products },
    },
  } = usePrismic()

  const { qUser, isAuthLoading } = useAuthContext()
  const { isStoreLoading } = useStoreContext()

  useEffect(() => {
    if (!isStoreLoading) return
    if (isAuthLoading) return
    ;(async () =>
      await Magento.Store.getSingleCategoryData({
        url_key: props.params['*'],
      }).then(({ categories }) => {
        let category = categories.items[0]
        const categoryProducts = filterProducts(category.products.items, qUser)
        category.products = categoryProducts
        setCategoryProducts(category)
      }))()
  }, [isAuthLoading])

  if (isStoreLoading && !categoryProducts)
    return <Loading loading={true} message={loading_products[0].text} />

  return (
    <>
      <Router primary={false} component={RouterComponent}>
        <Benefit
          path="/products/benefit/:url_key"
          categoryProducts={categoryProducts}
        />
      </Router>
    </>
  )
}

export default BenefitPage
